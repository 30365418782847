import axios from 'axios';
import store from '../store/index';

class Client {
  async send(category, title, body) {
    await axios.post(
      `https://themono.co.kr/api/files/${category}`,
      { title: title, content: body },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.accessToken,
        },
      }
    );
  }

  async edit(category, title, body, rename) {
    await axios.put(
      `https://themono.co.kr/api/files/${category}`,
      { title: title, content: body, rename: rename },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.accessToken,
        },
      }
    );
  }

  async get(category) {
    return await axios.get(`https://themono.co.kr/api/files/${category}`);
  }

  async getList(category, offset, limit) {
    return await axios.get(
      `https://themono.co.kr/api/files/${category}?offset=${offset}&limit=${limit}`
    );
  }

  async getDetail(category, title) {
    return await axios.get(`https://themono.co.kr/api/files/${category}/${title}`);
  }

  async delete(category, title) {
    return axios.delete(`https://themono.co.kr/api/files/${category}/${title}`, {
      headers: {
        Authorization: store.state.accessToken,
      },
    });
  }

  async sendCounseling(
    path,
    name,
    tel,
    address,
    detailAddress,
    postcode,
    buildType,
    usecase,
    area,
    range,
    date,
    budget
  ) {
    await axios.post(
      `https://themono.co.kr/api/counseling`,
      {
        path,
        name,
        tel,
        address,
        detailAddress,
        postcode,
        buildType,
        usecase,
        area,
        range,
        date,
        budget,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  async getCounselingList(offset, limit) {
    return await axios.get(
      `https://themono.co.kr/api/counseling/list?offset=${offset}&limit=${limit}`
    );
  }

  async getCounselingDetail(token) {
    return await axios.get(`https://themono.co.kr/api/counseling/token/${token}`);
  }

  login(password) {
    return axios.post(
      `https://themono.co.kr/api/admin/login`,
      {
        password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

export default new Client();
